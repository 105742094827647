<template>
  <div>
    <h1>Logout</h1>
    <span>Logging you out...</span>
  </div>
</template>

<script>
export default {
  mounted: function() {
    this.$store.dispatch('authentication/status/logout', null);
    this.$router.push({ name: 'Login' });
  },
};
</script>
