<template>
  <div class="wrapper">
    <h1>FAQ - Linux Foundation</h1>

    <br />

    <h2>
      Do I get free simulator sessions for my Linux Foundation exam?
    </h2>
    <p>
      Yes. <br /><br />
      If you purchased the CKS|CKA|CKAD|LFCS|LFCT exam, two simulator sessions
      will be already included. You'll find these in your Linux Foundation Exam
      Preparation Checklist. For more information you can check
      <a
        href="https://training.linuxfoundation.org/announcements/linux-foundation-kubernetes-certifications-now-include-exam-simulator"
        target="_blank"
        >The Simulator Announcement</a
      >
      ,
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/faq-cka-ckad-cks#is-there-training-to-prepare-for-the-certification-exam"
        target="_blank"
        >The CKA CKAD CKS Faq</a
      >
      and
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/frequently-asked-questions-lfct#where-can-i-find-practice-questions-for-lfct"
        target="_blank"
        >The LFCT Faq</a
      >. For questions you can also contact the
      <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
        Linux Foundation Support</a
      >.
    </p>

    <hr />
    <h2>
      How can I access my Linux Foundation simulator sessions?
    </h2>
    <p>
      You'll need to click on the link in your Linux Foundation Exam Preparation
      Checklist as shown below. If you don't have the link please contact the
      <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
        Linux Foundation Support </a
      >.
    </p>

    <b-img
      src="@/assets/lf_exam_prep_list_sisimulators.png"
      width="400px"
      fluid
      style="border: 1px solid lightgrey"
    ></b-img>

    <hr />
    <h2>
      Are the simulator sessions accessible through the Linux Foundation the
      very same as if purchased directly on killer.sh?
    </h2>
    <p>
      Yes.
    </p>

    <br />
    <br />

    <h1>FAQ - Remote Desktop PSI Bridge</h1>

    <p>
      The CKS|CKA|CKAD|LFCS exams have been updated to use the
      <a
        href="https://training.linuxfoundation.org/bridge-migration-2021"
        target="_blank"
        >PSI Bridge</a
      >
      where users get access to a Remote Desktop instead of just a Remote
      Terminal. There is an
      <a
        href="https://wuestkamp.medium.com/cks-cka-ckad-changed-terminal-to-remote-desktop-157a26c1d5e?source=friends_link&sk=3ae1ec2f0410cdb96bf49d2435761e1e"
        target="_blank"
        >an article</a
      >
      from us about it.
    </p>

    <br />

    <h2>Do the simulators have the Remote Desktop form 2022?</h2>
    <p>
      The CKS|CKA|CKAD|LFCS simulators use a very similar Remote Desktop
      interface like the real Exam UI. But there are still differences, see
      below. The Remote Desktop is also available on our partner platform
      <a
        href="https://killercoda.com/linux-foundation-exam-remote-desktop"
        target="_blank"
        >Killercoda</a
      >.
    </p>

    <hr />
    <h2>
      What are differences between the real exam Remote Desktop and the
      simulator implementation of it?
    </h2>
    <ul>
      <li>
        You'll need to use the PSI Secure Browser to access the real exams
        whereas you can use Chrome/Firefox to access the simulators.<br />
      </li>
      <li>
        You can only access certain allowed URLs in the Remote Desktop's Firefox
        of the real exams whereas there are no such restrictions in the
        simulators.
      </li>
      <li>
        In the simulators you can switch between the Remote Desktop and the
        Remote Terminal, this won't be possible in the real exams.
      </li>
    </ul>

    <hr />
    <h2>How can I copy & paste?</h2>
    <p>
      What always works: copy+paste using right mouse context menu<br />

      What works in Terminal: Ctrl+Shift+c and Ctrl+Shift+v<br />

      What works in other apps like Firefox: Ctrl+c and Ctrl+v<br />
    </p>

    <hr />
    <h2>Copy & Paste shows "Warning: Unsafe Paste"</h2>
    <p>
      This happens when you paste something into the Terminal. You can disable
      this in the "Terminal Preferences->General->Show unsafe paste dialog".<br />
      We don't disable this by default because the real exam will also have this
      setting to our knowledge.
    </p>

    <hr />
    <h2>The desktop is slow and lagging</h2>
    <p>
      This is because of the remote VNC connection. Moving windows or scrolling
      through browser pages will be slower than locally.<br />
      Always use a good internet connection without VPN/Proxy. Make sure no one
      else is downloading/uploading/streaming for best connection.
    </p>

    <hr />
    <h2>Can I view the K8s Docs in my local browser in the real exam?</h2>
    <p>
      No.
    </p>

    <hr />
    <h2>I resized the browser and now the desktop has a wrong resolution</h2>
    <p>
      Just slightly decrease or increase the left side (where questions are
      shown) by dragging the separator. This will cause the desktop's resolution
      to adjust again.
    </p>

    <hr />
    <h2>Can I access the Solutions and Score inside the remote desktop?</h2>
    <p>
      Yes, there are icons for this on the desktop of the remote desktop.
    </p>

    <hr />
    <h2>The desktop is not usable for me / there are errors</h2>
    <p>
      You can switch back to the remote terminal using the top menu "Exam
      Interface". The real exams will only have the remote desktop though.
    </p>

    <br />
    <br />

    <h1>FAQ - Before purchase</h1>

    <br />
    <h2>I’m in prep for the CKS|CKA|CKAD|LFCS|LFCT, is this for me?</h2>
    <p>
      Yes.
    </p>

    <hr />
    <h2>Which Kubernetes versions do the simulators have?</h2>
    <p>
      CKS: 1.30<br />
      CKA: 1.30<br />
      CKAD: 1.30
    </p>

    <hr />
    <h2>How are the simulators intended to be used?</h2>
    <p>
      You should see our simulators like the real exam, prepare yourself before
      you go in:
    </p>

    <ul>
      <li>Study somewhere else</li>
      <li>Start your first simulator session, learn from our solutions</li>
      <li>Study somewhere else somewhat more</li>
      <li>Start your second simulator session</li>
      <li>Rock the real exam</li>
    </ul>

    <p>
      Between starting your first and second test session could be days or even
      weeks.
    </p>

    <hr />
    <h2>How will the simulator help me in preparation?</h2>
    <p>
      You'll get used to the mindset of having to solve scenarios under
      pressure. Try to solve as many questions by yourself in the shortest
      amount of time without checking our solutions.<br />
      Our questions are of the same type like in the real exam and our solutions
      are detailed and often show general ways of approaching specific issues.
    </p>

    <hr />
    <h2>Do I have to solve the questions under pressure/countdown?</h2>
    <p>
      No, the countdown is optional.
    </p>

    <hr />
    <h2>I'm not doing the CKS, CKA or CKAD, is this for me?</h2>
    <p>
      If you like to test your Kubernetes knowledge, work, solve and investigate
      complex k8s scenarios, then maybe. But our simulators are for direct K8s
      exam preparation.
    </p>

    <hr />
    <h2>How many questions are there?</h2>
    <p>
      CKS: 22<br />
      CKA: 25<br />
      CKAD: 22<br />
      LFCS: 20<br />
      LFCT: 20<br /><br />
      In parts you have to create resources from scratch, some are given
      scenarios which you have to alter. Just like in the real exam.
    </p>

    <hr />
    <h2>Will I get other questions if I purchase a second time?</h2>
    <p>
      No, every simulator session of a type (CKS|CKA|CKAD|LFCS|LFCT) has the
      very same questions. If you decide you need more than two sessions, then a
      cheaper rebuy rate of 9.99$ per single session will be applied if you
      already purchased (or got Linux Foundation access for) the same simulator
      during the last 12 months. But we suggest to come prepared, then two
      sessions are enough.
    </p>

    <hr />
    <h2>Can I get a preview?</h2>
    <p>
      Once you register, before payment, you can preview 3 questions for the
      CKS|CKA|CKAD. These preview questions are in addition to the ones of the
      full course. The preview scenarios are also part of the cluster
      environments you'll be provided with, so you can solve the preview
      questions when purchasing the full course.
    </p>

    <hr />
    <h2>How much does this cost?</h2>
    <p>29.99$ for two CKS sessions.</p>
    <p>29.99$ for two CKA sessions.</p>
    <p>29.99$ for two CKAD sessions.</p>
    <p>29.99$ for two LFCS sessions.</p>
    <p>29.99$ for two LFCT sessions.</p>

    <hr />
    <h2>Can I pay in another currency than USD?</h2>
    <p>
      We only offer payments in USD. But the bank of your credit card will
      perform automatic currency conversion. Please contact your bank for info
      about additional costs.
    </p>

    <hr />
    <h2 class="font-weight-bold h5">
      Do you offer bulk or voucher purchases?
    </h2>
    <p>
      Yes, we offer companies to purchase vouchers in bulk. Any user using a
      voucher will gain simulator access without payment necessary. Please
      contact us on
      <router-link :to="{ name: 'Support' }">support</router-link>.<br />
    </p>

    <hr />
    <h2>Do you offer refunds or a testing period?</h2>
    <p>
      We don't offer this. If you had issues with your simulator session just
      contact support and we figure out a way to solve it.
    </p>

    <hr />
    <h2>Why don't you provide unlimited access?</h2>
    <p>
      We would like to provide unlimited access. But the environments that we
      create on demand cost good amount of cloud resources. See it like this: if
      a simulator has 20 scenarios, then these 20 scenarios are always running.
      Even if you spend one hour just on scenario 1, we have to provide the
      resources for all scenarios.<br />
      We decided to implement it this way to simulate the real CKS|CKA|CKAD
      environments where this is also the case. We offer cheaper re-buys of the
      same simulator if you already purchased it during the last 12 months.
    </p>

    <hr />
    <h2>How is the difficulty compared to the real certification?</h2>
    <p>
      This simulator is more difficult than the real certification. We think
      this gives you a greater learning effect and also confidence to score in
      the real exam. Most of the simulator scenarios require good amount of work
      and can be considered "hard". In the real exam you will also face these
      "hard" scenarios, just less often. There are probably also fewer questions
      in the real exam.
    </p>

    <hr />
    <h2>Will you calculate a score?</h2>
    <p>
      Yes. <br /><br />

      But for some questions it's really up to you to compare your solutions
      with ours. We think it has a great learning effect if you study our
      detailed ones, because in there we also provide additional tips and
      information which you might otherwise skip if you just look at the
      score.<br />
    </p>

    <hr />
    <h2>Why don't you show percentages on the score?</h2>
    <p>
      Because we don't want to give the impression that if you get a certain
      percentage in our simulator you would also get that percentage in the real
      exam.
    </p>

    <hr />
    <h2>Do you calculate the score the same way like the real exams?</h2>
    <p>
      No. Our score will just give you an idea where you are at.
    </p>

    <hr />
    <h2>Will there be partial scoring in the real exam?</h2>
    <p>
      Yes. The Linux Foundation will calculate a score based on successful
      subtasks. Also considering if some subtasks are harder than others.
    </p>

    <hr />
    <h2>What’s the expiration?</h2>
    <p>
      Once you make the purchase you can activate your sessions independently
      over the time of one year.
    </p>

    <hr />
    <h2>Why do both simulator sessions have identical scenarios?</h2>
    <p>
      In the beginning, our idea was to just provide one session for you. But
      because our simulators are harder than the real exam you might need two
      tries.
    </p>

    <p>
      Even if the two sessions contain the same scenarios, it won’t help you too
      much if time has passed. The K8s exams are about breathing kubectl and
      being able to quickly implement K8s resources.
    </p>

    <hr />
    <h2>What happens once I activate a test session?</h2>
    <p>
      If you activate a session we’ll spin up your own private cluster, loaded
      with test scenarios and various tasks to solve. We'll also provide you
      with some information to read about the real exam. Once your cluster is
      ready you’ll have 120 minutes to solve these. The timer is optional and
      just an indicator for yourself.
    </p>

    <hr />
    <h2>How long can I access a test session?</h2>
    <p>
      When you start a test session, your cluster environment is created. Once
      your cluster environment is created, takes up to 10 minutes, you have 36
      hours access to it. These 36 hours cannot be spread over several days or
      so. When you start your test session on Tuesday at 8:16am you can access
      it till Wednesday 8:16pm (plus ~10 minutes considering the boot time).
    </p>

    <hr />
    <h2>What happens once the session countdown reaches 0?</h2>
    <p>
      When the 120 minutes are down to 0 you’ll be able to view the detailed
      example solutions to the scenarios and can compare these to yours. You’ll
      still have access to your cluster, 36hrs in total, to play around further.
      You’ll always have access to the questions and solutions.
    </p>

    <hr />
    <h2>Can I stop the countdown early to directly access the solutions?</h2>
    <p>
      Yes, but we suggest you try solving these at first by yourself.
    </p>

    <hr />
    <h2>Can I restart a cluster environment?</h2>
    <p>
      Yes, while in the 36 hour window. This will completely reset the
      environment and you start from scratch. The time it takes for the new
      cluster environment to boot up will be added to the expiration date, so
      you won't lose any time.
    </p>

    <hr />
    <h2>Is this course for me if I have zero knowledge of Kubernetes?</h2>
    <p>
      No, best is you already have some knowledge about Kubernetes and are
      somewhat comfortable with kubectl. This course is mainly for testing your
      existing knowledge if you’re chasing the CKS, CKA or CKAD.
    </p>

    <hr />
    <h2>How can I log in?</h2>
    <p>
      Currently you can login using the external services
    </p>
    <ul>
      <li>Linux Foundation</li>
      <li>Google</li>
      <li>Github</li>
      <li>Gitlab</li>
      <li>Microsoft</li>
      <li>Linkedin</li>
    </ul>
    <p>
      You can also login using Email where we send a login link to you without
      the need of defining a password. Read more
      <a
        class="link-normal link-underline"
        href="https://blog.medium.com/signing-in-to-medium-by-email-aacc21134fcd"
        target="_blank"
        >here</a
      >
      regarding the reason.
    </p>

    <hr />
    <h2>Where can I see my email address used to log in?</h2>
    <p>
      You can see it in your
      <router-link :to="{ name: 'UserProfile' }">
        Account Section
      </router-link>
      .
    </p>

    <hr />
    <h2>How can I pay?</h2>
    <p>You can pay via Credit Card.</p>

    <hr />
    <h2>
      Can I stream or create videos with killer.sh content?
    </h2>
    <p>
      Please request permission via
      <router-link :to="{ name: 'Support' }">Support</router-link>.
    </p>

    <hr />
    <h2>What if I still have questions?</h2>
    <p>
      You can contact us at
      <router-link :to="{ name: 'Support' }">Support</router-link>
    </p>

    <hr />
    <h2>Who is behind this?</h2>
    <p>
      We are a group of Kubernetes and hands-on exam enthusiast. What we have in
      common is that we are amazed by IaC and it’s various technologies. The
      idea of this platform was created and developed by
      <a href="https://wuestkamp.com" target="_blank">Kim Wüstkamp</a>.
    </p>
    <p>
      The Kubernetes Certifications really are setup in the way online
      certificates should be. No memorization or multiple choice. Just plain
      hands-on, challenging practical exercises while you have access to the
      complete documentation.
    </p>

    <br /><br />

    <h1 id="session-runtime">FAQ - Session Runtime</h1>

    <br />
    <h2>Can I close or reload the browser without losing my solutions?</h2>
    <p>
      Yes.<br />
      For the 36hrs your session will be kept running in the background. You can
      close the window or even use a different browser.
    </p>

    <br />
    <h2>My session should still be active but I can't access it?</h2>
    <p>
      Make sure you select the correct one from your dashboard. Sessions are
      accessible for the next 36hrs after activation. The 36hrs are time based
      and not usage based.
    </p>

    <hr />
    <h2>SSH connection issue, do I need a password?</h2>
    <p>
      From your main terminal and user "k8s" you can ssh into all nodes in the
      environment without a password. Make sure your terminal shows
      "k8s@terminal" and not "root@terminal" or "root@cluster1-master1" etc.
    </p>

    <hr />
    <h2>Slow or interrupted connection?</h2>
    <p>
      If you experience any kind of issues please make sure all points here are
      complied with:
    </p>
    <ul>
      <li>
        <strong>Browser</strong>: only latest Chrome and latest Firefox are
        supported
      </li>
      <li>
        <strong>Ubuntu+Chrome</strong>: users report keyboard issues, switch to
        Firefox or Chromium
      </li>
      <li>
        <strong>Extensions</strong>: disable ALL extensions/plugins and run in
        private mode
      </li>
      <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
      <li>
        <strong>Internet</strong>: use a stable internet connection, with low
        usage by others
      </li>
    </ul>

    <hr />
    <h2>I have keyboard issues, some keys don't work in the terminal</h2>
    <p>
      For OSX it could be your keyboard layout. Please go to
      Preferences->Keyboard->Input Sources. There search for English and then
      select "ABC" (or maybe called "Default"). Switching the layout should
      solve the input issue.
    </p>
    <p>
      For Windows try to change your keyboard layout to a different English one.
    </p>
    <p>
      For Ubuntu+Chrome for example users report keyboard issues, switch to
      Firefox or Chromium. Also open killer.sh in private browser mode.
    </p>

    <hr />
    <h2>My environment is misconfigured and not reachable/working properly</h2>
    <p>
      You can restart your environment via "Exam Controls -> Restart Session".
    </p>

    <hr />
    <h2>Where can I restart my cluster environment?</h2>
    <p>
      While in the 36hrs access window you can select to your active terminal.
      You find the action under "Exam Controls -> Restart Session".
    </p>

    <hr />
    <h2>I have a question about a scenario</h2>
    <p>
      Please ask all content questions in our
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a>
      </span>
      <span v-else>
        Slack
      </span>
      community.
    </p>

    <hr />
    <h2>Why does the score not work for my solution?</h2>
    <p>
      Please apply our provided solution step-by-step. If it still doesn't work
      please restart your environment ("Exam Controls -> Restart Session") and
      try again. Compare our solution closely to yours, you can also post your
      solution in our
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a>
      </span>
      <span v-else>
        Slack
      </span>
      community.
    </p>

    <hr />
    <h2>Where can I see the solutions?</h2>
    <p>
      Head to your active terminal. On the "Exam Controls" you find a menu with
      item "Solutions".
    </p>

    <hr />
    <h2>Where is the course/simulator content?</h2>
    <p>
      The full course content (questions+solutions+tips) will be available once
      the countdown of an activated session reached 0. Check one below to see
      how to access the content earlier.
      <br />Head to your active terminal. On the "Exam Controls" you find a menu
      with item "Solutions". This link will contain the complete course content.
    </p>

    <hr />
    <h2>
      Can I access the course/simulator content before activating a session?
    </h2>
    <p>
      We strongly believe that you get the best learning effect if you try to
      solve the solutions by yourself without access to the solutions. If you
      rather disagree with this approach you can access the solutions by adding
      /content to the url of the Activate Simulator Session screen. Like
      this:<br />
      https://killer.sh/attendee/{YOUR_SESSION_UUID}/content
    </p>

    <hr />
    <h2>
      More Questions?
    </h2>
    <p>
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a> for scenario
        and content discussions
      </span>
      <span v-else>
        Slack for scenario and content discussions
      </span>
      <br />
      <router-link :to="{ name: 'Support' }">Support</router-link> for
      session/account issues
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    isAuthenticated: 'authentication/status/isAuthenticated',
    user: 'user/list/data',
    isLoading: 'user/list/isLoading',
  }),
  methods: {
    userHasGoodProfile: function() {
      return this.user.providedAllDataToMakePurchase === true;
    },
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>
