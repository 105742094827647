<template>
  <div>
    <div v-if="isLoading || userIsLoading">
      <Spinner></Spinner>
    </div>
    <UserConsent
      v-else-if="
        this.consentNeedsConsentSubmit ||
          this.consentNeedsTermsAndConditionsAccept
      "
      v-bind:termsAcceptNecessary="true"
    ></UserConsent>
    <OrderSelectCreate
      v-else-if="!this.hasAttendeeRecords && !this.isLoading"
    ></OrderSelectCreate>
    <div v-else class="wrapper">
      <div v-bind:class="{ 'd-none': isLoading && !firstLoadingCalled }">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <span v-if="!hasAttendeeRecords">
          No attendee records
        </span>
        <span v-if="hasAttendeeRecords">
          <router-link
            :to="{ name: 'OrderSelectCreate' }"
            class="link-normal pull-right"
          >
            Buy Sessions
          </router-link>

          <AttendeeList></AttendeeList>

          <br /><br /><br /><br />

          <small>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <p class="text-center">
                  <font-awesome-icon
                    :icon="['fab', 'twitter']"
                    size="1x"
                    class="mr-3"
                  />
                  <a href="https://twitter.com/_killer_shell" target="_blank"
                    >Twitter</a
                  >
                  for updates
                </p>
              </div>
              <div class="col-md-4 col-sm-12">
                <p v-if="isAuthenticated && isFullMember()" class="text-center">
                  <font-awesome-icon
                    :icon="['fab', 'slack']"
                    size="1x"
                    class="mr-3"
                  />
                  <a href="https://killer.sh/slack" target="_blank">Slack</a>
                  for discussions
                </p>
              </div>
              <div class="col-md-4 col-sm-12">
                <p class="text-center">
                  <font-awesome-icon icon="envelope" size="1x" class="mr-3" />
                  <router-link :to="{ name: 'Support' }">
                    Support
                  </router-link>
                  for help
                </p>
              </div>
            </div>
          </small>
        </span>
      </div>
      <div v-if="isLoading">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AttendeeList from '@/components/attendee/List';
import Spinner from '@/components/util/Spinner';
import OrderSelectCreate from '@/components/order/OrderSelectCreate';
import UserConsent from '@/components/user/UserConsent';

export default {
  components: {
    AttendeeList,
    Spinner,
    OrderSelectCreate,
    UserConsent,
  },
  data: function() {
    return {
      coursePayComponentShown: false,
      firstLoadingCalled: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'attendee/list/isLoading',
      error: 'attendee/list/error',
      attendees: 'attendee/list/data',
      consentNeedsConsentSubmit: 'consent/list/needsConsentSubmit',
      consentNeedsTermsAndConditionsAccept:
        'consent/list/needsTermsAndConditionsAccept',
      consentIsLoading: 'consent/list/isLoading',
      user: 'user/list/data',
      userIsLoading: 'user/list/isLoading',
      isAuthenticated: 'authentication/status/isAuthenticated',
    }),
    hasAttendeeRecords: function() {
      return this.attendees && this.attendees.length > 0;
    },
  },

  watch: {
    isLoading(newValue) {
      if (!newValue) {
        this.firstLoadingCalled = true;
      }
    },
  },

  created() {
    this.orderSelectionReset();
    this.getUserObject();
    this.getConsents();
    this.getAttendees();
    this.firstLoadingCalled = false;
  },
  methods: {
    ...mapActions({
      getAttendees: 'attendee/list/getAttendees',
      getConsents: 'consent/list/getConsents',
      orderSelectionReset: 'order/selection/reset',
      getUserObject: 'user/list/getUserObject',
    }),
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.spinner {
  margin: 0 auto;
  width: 60px;
}

.spring-spinner-rotator {
  border-right-color: #000000 !important;
  border-top-color: #000000 !important;
}
</style>
