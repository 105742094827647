<template>
  <div class="home-container">
    <b-container>
      <b-row id="hero-section">
        <b-col md="3" sm="12" class="hero-text">
          <span class="text-highlight"
            >CKS<br />CKA<br />CKAD<br />LFCS<br />LFCT</span
          >
          Simulator
          <router-link :to="{ name: 'Dashboard' }" class="btn hero-cta">
            Get Started
          </router-link>
        </b-col>
        <b-col md="9" sm="12">
          <b-img
            src="@/assets/undraw_programmer_imem.svg"
            fluid
            alt="hero-image"
          ></b-img>
          <b-img
            src="@/assets/k8s_logo_dark.svg"
            fluid
            alt="hero-image"
            class="k8s-logo"
          ></b-img>
        </b-col>
      </b-row>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_cks_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCksClick"
              style="padding: 0 20px; max-width: 230px; float: left"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKS Simulator">
              <b-card-text>
                Our CKS simulator will provide you with an environment
                containing 22 scenarios and their solutions. Verify your K8s
                security skills and prepare for the CKS exam.
                <router-link
                  :to="{ name: 'CksSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row class="hero-card flash-card-wrapper">
          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCksClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">
                  22
                </div>
                <div class="big2-text">scenarios</div>
              </div>
              <b-card-text>
                Access to 22 CKS practice scenarios and detailed solutions
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  style="display: inline-block"
                />
              </span>
            </b-card>
          </b-col>

          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCksClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">120</div>
                <div class="big2-text">minutes</div>
              </div>
              <b-card-text class="text-box">
                Test your knowledge under pressure with our (optional) countdown
              </b-card-text>
              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>

          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCksClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">36h</div>
                <div class="big2-text">Cluster</div>
              </div>

              <b-card-text>
                Your environment is open for the following 36hrs after
                activation
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_cka_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCkaClick"
              style="padding: 0 20px; max-width: 250px; float: left"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKA Simulator">
              <b-card-text>
                We provide you with access to a test environment which comes
                close to the real one. Our CKA Simulator comes with 25 complex
                scenarios to solve in 120 minutes plus additional 5 extra ones.
                After the test session you will get access to detailed
                solutions.
                <router-link
                  :to="{ name: 'CkaSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row class="hero-card flash-card-wrapper">
          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCkaClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">
                  25
                </div>
                <div class="big2-text">scenarios</div>
              </div>
              <b-card-text>
                Access to 25 CKA practice scenarios and detailed solutions
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  style="display: inline-block"
                />
              </span>
            </b-card>
          </b-col>

          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCkaClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">120</div>
                <div class="big2-text">minutes</div>
              </div>
              <b-card-text class="text-box">
                Test your knowledge under pressure with our (optional) countdown
              </b-card-text>
              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>

          <b-col md="4" sm="12" class="flash-card" v-on:click="toAboutCkaClick">
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">36h</div>
                <div class="big2-text">Cluster</div>
              </div>

              <b-card-text>
                Your environment is open for the following 36hrs after
                activation
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_ckad_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCkadClick"
              style="padding: 0 20px; max-width: 250px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKAD Simulator">
              <b-card-text>
                We provide you with access to a test environment which comes
                really close to the original one. Our CKAD Simulator has 20
                complex questions/scenarios to solve in 120 minutes. After the
                session you will get access to detailed solutions.
                <router-link
                  :to="{ name: 'CkadSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row class="hero-card flash-card-wrapper">
          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutCkadClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">22</div>
                <div class="big2-text">scenarios</div>
              </div>
              <b-card-text>
                Access to 22 CKAD practice scenarios and detailed solutions
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  style="display: inline-block"
                />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutCkadClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">120</div>
                <div class="big2-text">minutes</div>
              </div>
              <b-card-text class="text-box">
                Test your knowledge under pressure with our (optional) countdown
              </b-card-text>
              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutCkadClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">36h</div>
                <div class="big2-text">Cluster</div>
              </div>

              <b-card-text>
                Your environment is open for the following 36hrs after
                activation
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/lfcs_logo.png"
              class="rounded-0"
              v-on:click="toAboutLfcsClick"
              style="padding: 0 20px; max-width: 200px; float: left; margin: 30px 0 0 30px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="LFCS Simulator">
              <b-card-text>
                Our brand new LFCS simulator will provide you with a practice
                environment containing 20 scenarios and solutions. Verify your
                Linux SysAdmin skills and prepare for the exam.
                <router-link
                  :to="{ name: 'LfcsSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row class="hero-card flash-card-wrapper">
          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfcsClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">
                  20
                </div>
                <div class="big2-text">scenarios</div>
              </div>
              <b-card-text>
                Access to 20 LFCS practice scenarios and detailed solutions
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  style="display: inline-block"
                />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfcsClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">120</div>
                <div class="big2-text">minutes</div>
              </div>
              <b-card-text class="text-box">
                Test your knowledge under pressure with our (optional) countdown
              </b-card-text>
              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfcsClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">36h</div>
                <div class="big2-text">Environment</div>
              </div>

              <b-card-text>
                Your environment is open for the following 36hrs after
                activation
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/lfct_logo.png"
              class="rounded-0"
              v-on:click="toAboutLfctClick"
              style="padding: 0 20px; max-width: 200px; float: left; margin: 30px 0 0 30px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="LFCT Simulator">
              <b-card-text>
                Our new LFCT simulator will provide you with an environment
                containing 20 scenarios and their solutions. Verify your Linux
                cloud skills and prepare for the LFCT exam.
                <router-link
                  :to="{ name: 'LfctSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row class="hero-card flash-card-wrapper">
          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfctClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">
                  20
                </div>
                <div class="big2-text">scenarios</div>
              </div>
              <b-card-text>
                Access to 20 LFCT practice scenarios and detailed solutions
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  style="display: inline-block"
                />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfctClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">120</div>
                <div class="big2-text">minutes</div>
              </div>
              <b-card-text class="text-box">
                Test your knowledge under pressure with our (optional) countdown
              </b-card-text>
              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>

          <b-col
            md="4"
            sm="12"
            class="flash-card"
            v-on:click="toAboutLfctClick"
          >
            <b-card style="max-width: 20rem;" class="mb-2 card-widget">
              <div class="text-center">
                <div class="big-text">36h</div>
                <div class="big2-text">Environment</div>
              </div>

              <b-card-text>
                Your environment is open for the following 36hrs after
                activation
              </b-card-text>

              <span class="flash-arrow">
                <span>Learn More</span>
                <font-awesome-icon icon="long-arrow-alt-right" />
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col
          md="12"
          sm="12"
          class="text-center"
          style="margin: 50px 0 150px 0"
        >
          <div class="center-button">
            <div v-if="isAuthenticated">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn hero-cta fit-content pull-right"
              >
                Preview Questions
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn hero-cta fit-content pull-right"
              >
                Login to preview scenarios
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.home-card {
  img {
    cursor: pointer;
  }
}

.center-button {
  width: 300px;
  margin: 0 auto;
}

.k8s-logo {
  margin: 20px 20px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}

.flash-card-wrapper {
  justify-content: space-between;

  .flash-arrow {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    color: var(--primary-color);
    font-weight: bold;
  }

  .flash-card {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .flash-card:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .big-text {
    color: var(--secondary-color);
    font-size: 3.2rem;
    font-weight: bold;
  }

  .big2-text {
    color: var(--secondary-color);
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 20px;
  }
}

.hero-text {
  font-size: 4rem;
}

.card-widget {
  margin: 30px auto 0 auto;
}

.home-card {
  margin: 100px 0;
  padding: 50px 0;
}

.home-container {
  background-color: #fff;
  padding-bottom: 50px;
}

.text-highlight {
  color: var(--ckad-color-blue);
}

#hero-section {
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  .btn {
    width: 100%;
  }
  .hero-cta {
    font-size: 0.9rem;
    margin: 1.1rem auto;
  }

  .hero-text {
    font-size: 2.5rem;
  }

  #hero-section {
    text-align: center;
    padding: 30px 0;
  }

  .flash-card {
    .card {
      max-width: 100% !important;
    }
  }
  .k8s-logo {
    width: 25%;
  }
  .center-button {
    width: 100%;
    margin: 0 auto;
  }
}
</style>

<script>
import router from '@/router/router';
import { mapGetters } from 'vuex';

export default {
  methods: {
    toAboutLfctClick: function() {
      router.push({ name: 'LfctSimulatorAbout' });
    },
    toAboutLfcsClick: function() {
      router.push({ name: 'LfcsSimulatorAbout' });
    },
    toAboutCksClick: function() {
      router.push({ name: 'CksSimulatorAbout' });
    },
    toAboutCkaClick: function() {
      router.push({ name: 'CkaSimulatorAbout' });
    },
    toAboutCkadClick: function() {
      router.push({ name: 'CkadSimulatorAbout' });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/status/isAuthenticated',
    }),
  },
};
</script>
