import { ENTRYPOINT } from '../config/entrypoint';
import router from '@/router/router';
import store from '@/store';
import * as statusMaintenanceMutationTypes from '@/store/modules/status/maintenance/mutation_types';
import Vue from 'vue';

const MIME_TYPE = 'application/ld+json';

function markMaintenance(payload) {
  store.commit(
    'status/maintenance/' + statusMaintenanceMutationTypes.SET_ACTIVE,
    payload
  );
}

export default function(id, options = {}) {
  if (typeof options.headers === 'undefined') {
    Object.assign(options, { headers: new Headers() });
  }

  let token = store.getters['authentication/status/jwtToken'];
  let refreshJwtToken = store.getters['authentication/status/jwtRefreshToken'];

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  let clientVersion = process.env.VUE_APP_CLIENT_VERSION;
  if (clientVersion && !id.includes('https://') && !id.includes('http://')) {
    options.headers.set('killer-shell-client-version', clientVersion);
  }

  if (options.headers.get('Accept') === null) {
    options.headers.set('Accept', MIME_TYPE);
  }

  if (
    options.body !== undefined &&
    !(options.body instanceof FormData) &&
    options.headers.get('Content-Type') === null
  ) {
    options.headers.set('Content-Type', MIME_TYPE);
  }

  //options.credentials = 'include';
  //options.mode = 'no-cors';

  const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

  return fetch(new URL(id, entryPoint), options).then(response => {
    if (response.status === 500) {
      response.json().then(json => {
        // console.log(json);
        if ('maintenance' in json) {
          if (json['maintenance'] === true) {
            markMaintenance(true);
          }
        }
      });
    } else {
      markMaintenance(false);

      if (response.status === 429) {
        Vue.prototype.$flashStorage.flash(
          'Too many requests. Please wait a few minutes, then reload the page',
          'error',
          {
            timeout: 999999999,
          }
        );
      }
    }

    if (response.ok) {
      return response;
    }

    if (response.status === 401) {
      // jwt token is expired or invalid
      if (refreshJwtToken) {
        router.push({ name: 'Login' }); // but we have a refresh token
      } else {
        router.push({ name: 'Logout' }); // we have no refresh token
      }
      return response;
    }

    return response.json().then(json => {
      const error = json['hydra:description']
        ? json['hydra:description']
        : JSON.stringify(json);
      if (!json.violations) throw Error(error);

      const errors = { _error: error };
      errors.violations = [];
      json.violations.map(violation =>
        Object.assign(errors.violations, {
          [violation.propertyPath]: violation.message,
        })
      );

      throw { errors: errors };
    });
  });
}
