<template>
  <div>
    <div>
      <b-row class="row">
        <b-col cols="9"
          ><b-form-select
            type="text"
            v-model="ticket.category"
            :options="categories"
            @change="categorySwitched()"
        /></b-col>
      </b-row>

      <div class="mt-5"></div>

      <span v-if="ticket.category === 'general'">
        Most general questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <br />
        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'billing'">
        Most billing questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.

        <div class="mt-2"></div>

        <br />
        <hr />
        <h2>How much does this cost?</h2>
        <p>29.99$ for two CKS sessions.</p>
        <p>29.99$ for two CKA sessions.</p>
        <p>29.99$ for two CKAD sessions.</p>
        <p>29.99$ for two LFCT sessions.</p>

        <hr />
        <h2>How can I pay in another currency than USD?</h2>
        <p>
          We only offer payments in USD. But the bank of your credit card or
          Paypal will perform automatic currency conversion. Please contact your
          bank for info about additional costs.
        </p>

        <hr />
        <h2>Do you offer refunds or a testing period?</h2>
        <p>
          We don't offer this. If you had issues with your simulator session
          just contact support and we figure out a way to solve it.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'content'">
        <div class="mt-2"></div>
        <br />
        <hr />
        <h2>Why do both simulator sessions have identical scenarios?</h2>
        <p>
          In the beginning, our idea was to just provide one session for you.
          But because our simulators are harder than the real exam you might
          need two tries.
        </p>

        <p>
          Even if the two sessions contain the same scenarios, it won’t help you
          too much if time has passed. The K8s exams are about breathing kubectl
          and being able to quickly implement K8s resources.
        </p>
        <hr />
        <h2>Will I get other questions if I purchase a second time?</h2>
        <p>
          No, every simulator session of a type (CKS|CKA|CKAD) has the very same
          questions. If you decide you need more than two sessions, then a
          cheaper rebuy rate of 9.99$ per session will be applied if you already
          purchased the same simulator during the last 12 months. But we suggest
          to come prepared, then two sessions are enough.
        </p>

        <hr />
        <h2>How many questions are there?</h2>
        <p>
          CKS: 22<br />
          CKA: 25<br />
          CKAD: 20<br /><br />
          In parts you have to create resources from scratch, some are given
          scenarios which you have to alter. Just like in the real exam.
        </p>
        <hr />

        <div class="mt-5"></div>

        If you have questions to simulator scenarios or your solution please ask
        these in our<br />
        <a v-if="showSlackLink" href="https://killer.sh/slack" target="_blank"
          >Slack Community</a
        >
        <span v-else>Slack Community</span>.
      </span>

      <span v-if="ticket.category === 'score'">
        Most score questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <div class="mt-2"></div>

        <br />
        <hr />
        <h2>Why does the score not work for my solution?</h2>
        <p>
          Please apply our provided solution step-by-step. If it still doesn't
          work please restart your environment ("Exam Controls -> Restart
          Session") and try again. Compare our solution closely to yours, you
          can also post your solution in our
          <a v-if="showSlackLink" href="https://killer.sh/slack" target="_blank"
            >Slack Community</a
          >
          <span v-else>Slack Community</span>.
        </p>
        <hr />
        <h2>How is the difficulty compared to the real certification?</h2>
        <p>
          The simulators are more difficult than the real certifications. We
          think this gives you a greater learning effect and also confidence to
          score in the real exam. Most of our scenarios require good amount of
          work and can be considered "hard". In the real exam you will also face
          these "hard" scenarios, just less often. If you can solve the
          simulator scenarios you should've no problems in the real exam.
        </p>

        <hr />
        <h2>Will you calculate a score?</h2>
        <p>
          Yes. <br /><br />

          But for some questions it's really up to you to compare your solutions
          with ours. We think it has a great learning effect if you study our
          detailed ones, because in there we also provide additional tips and
          information which you might otherwise skip if you just look at the
          score.<br />
        </p>

        <hr />
        <h2>Why don't you show percentages on the score?</h2>
        <p>
          Because we don't want to give the impression that if you get a certain
          percentage in our simulator you would also get that percentage in the
          real exam.
        </p>

        <hr />
        <h2>Do you calculate the score the same way like the real exams?</h2>
        <p>
          No. Our score will just give you an idea where you are at.
        </p>

        <hr />
        <h2>Will there be partial scoring in the real exam?</h2>
        <p>
          Yes. The Linux Foundation will calculate a score based on successful
          subtasks. Also considering if some subtasks are harder than others.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'linux_foundation'">
        Most questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <div class="mt-2"></div>

        <br />
        <hr />

        <h2>
          Do I get free simulator sessions for my Linux Foundation exam?
        </h2>
        <p>
          Yes. <br /><br />
          If you purchased your CKS|CKA|CKAD exam, two simulator sessions will
          be already included. You'll find these in your Linux Foundation Exam
          Preparation Checklist. For more information please refer to
          <a
            href="https://training.linuxfoundation.org/announcements/linux-foundation-kubernetes-certifications-now-include-exam-simulator"
            target="_blank"
            >this</a
          >
          and
          <a
            href="https://docs.linuxfoundation.org/tc-docs/certification/faq-cka-ckad-cks#is-there-training-to-prepare-for-the-certification-exam-1"
            target="_blank"
            >this</a
          >.<br />
          For access questions please contact the
          <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
            Linux Foundation Support </a
          >.
        </p>

        <hr />
        <h2>
          How can I access my Linux Foundation simulator sessions?
        </h2>
        <p>
          You can find these in your Linux Foundation Exam Preparation
          Checklist:
        </p>

        <b-img
          src="@/assets/lf_exam_prep_list_sisimulators.png"
          width="400px"
          fluid
          style="border: 1px solid lightgrey"
        ></b-img>

        <p>
          If you don't have the link please contact the
          <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
            Linux Foundation Support
          </a>
        </p>

        <hr />
        <h2>
          Are the simulator sessions accessible through the Linux Foundation the
          very same as if purchased directly on killer.sh?
        </h2>
        <p>
          Yes.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'session_error'">
        Most session runtime questions are answered in our
        <router-link :to="{ name: 'FaqSessionRuntime' }" target="_blank">
          <strong>FAQ Session Runtime</strong> </router-link
        >.

        <div class="mt-2"></div>

        <br />
        <hr />
        <h2>My session should still be active but I can't access it?</h2>
        <p>
          Make sure you select the correct one from your dashboard. Sessions are
          accessible for the next 36hrs after activation. The 36hrs are time
          based and not usage based.
        </p>

        <hr />
        <h2>Can I close or reload the browser without losing my solutions?</h2>
        <p>
          Yes.<br />
          For the 36hrs window your session will be kept running in the
          background. You can close the window or even use a different browser.
        </p>

        <hr />
        <h2>SSH connection issue, do I need a password?</h2>
        <p>
          From your main terminal and user "k8s" you can ssh into all nodes in
          the environment without a password. Make sure your terminal shows
          "k8s@terminal" and not "root@terminal" or "root@cluster1-master1" etc.
        </p>

        <hr />
        <h2>Slow or interrupted connection?</h2>
        <p>
          If you experience any kind of issues please make sure all points here
          are complied with:
        </p>
        <ul>
          <li>
            <strong>Browser</strong>: only latest Chrome and latest Firefox are
            supported
          </li>
          <li>
            <strong>Extensions</strong>: disable ALL extensions/plugins and run
            in private mode
          </li>
          <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
          <li>
            <strong>Internet</strong>: use a stable internet connection, with
            low usage by others
          </li>
        </ul>

        <hr />
        <h2>I have keyboard issues, some keys don't work in the terminal</h2>
        <p>
          For Ubuntu+Chrome for example users report keyboard issues, switch to
          Firefox or Chromium. Also open killer.sh in private browser mode.
        </p>

        <hr />
        <h2>
          My environment is misconfigured and not reachable/working properly
        </h2>
        <p>
          You can restart your environment. On the "Exam Controls" you find a
          menu with item "Restart Session".
        </p>

        <hr />
        <h2>Where can I restart my cluster environment?</h2>
        <p>
          While in the 36hrs access window you can select to your active
          terminal. On the "Exam Controls" you find a menu with item "Restart
          Session".
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'account_error'">
        Most account questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.

        <div class="mt-2"></div>
        <br />
        <hr />
        <h2>How can I log in?</h2>
        <p>
          Currently you can login using the external services Google, Github,
          Gitlab, Microsoft and Linkedin. You can also login using Email where
          we send a login link to you without the need of defining a password.
          Read more
          <a
            class="link-normal link-underline"
            href="https://blog.medium.com/signing-in-to-medium-by-email-aacc21134fcd"
            target="_blank"
            >here</a
          >
          regarding the reason.
        </p>

        <hr />
        <h2>Can I close or reload the browser without losing my solutions?</h2>
        <p>
          Yes.<br />
          For the 36hrs your session will be kept running in the background. You
          can close the window or even use a different browser.
        </p>

        <hr />
        <h2>Where can I see my email address used to log in?</h2>
        <p>
          You can see it in your
          <router-link :to="{ name: 'UserProfile' }">
            Account Section
          </router-link>
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <div v-if="userChoiceShowForm" class="mt-5">
        <div class="alert alert-info">
          If you would like to attach files or pictures, please create the
          request at first without. Then attach those to the confirmation email
          you'll receive.
        </div>

        <div v-if="this.validationError" class="alert alert-danger">
          Please fill out all fields.
        </div>

        <b-row class="row">
          <b-col cols="3">Name: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.name"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Email: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.email"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Subject: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.subject"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Text: </b-col>
          <b-col><b-form-textarea v-model="ticket.text" rows="6"/></b-col>
        </b-row>

        <div>
          <spinner v-if="isLoading"></spinner>
          <div v-else-if="this.error">
            <div class="alert alert-danger">
              An error occurred. Please fill out all fields or try again later.
            </div>
          </div>
          <div v-else-if="this.sent">
            <div class="alert alert-success">
              We received your request and will usually get back to you within
              two days.
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <button
                  v-on:click="create()"
                  class="mt-4 btn card-cta-primary pull-right"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  name: 'TicketCreate',
  components: { Spinner },
  data: function() {
    return {
      user: null,
      ticket: {
        name: '',
        email: '',
        subject: '',
        text: '',
        category: null,
      },
      categories: [
        { value: null, text: 'Please select' },
        { value: 'linux_foundation', text: 'Linux Foundation access' },
        { value: 'general', text: 'General question' },
        { value: 'billing', text: 'Billing question' },
        { value: 'content', text: 'Simulator content question' },
        { value: 'score', text: 'Scoring question' },
        { value: 'session_error', text: 'Session error or access issue' },
        { value: 'account_error', text: 'Account error or access issue' },
      ],
      sent: false,
      validationError: false,
      userChoiceShowForm: false,
    };
  },
  props: {
    userPassed: {
      required: false,
      type: Object,
    },
    showSlackLink: {
      required: true,
      type: Boolean,
    },
  },
  computed: mapGetters({
    isLoading: 'support/create/isLoading',
    error: 'support/create/error',
  }),
  created() {
    this.clearError();
    if (this.userPassed) {
      this.ticket.name = this.userPassed.firstName;
      this.ticket.email = this.userPassed.email;
    }
  },
  methods: {
    ...mapActions({
      createTicket: 'support/create/create',
      clearError: 'support/create/clearError',
    }),
    categorySwitched: function() {
      this.userChoiceShowForm = false;
    },
    userChoiceShowFormToggle: function() {
      this.userChoiceShowForm = !this.userChoiceShowForm;
    },
    create: function() {
      if (
        this.ticket.name &&
        this.ticket.email &&
        this.ticket.subject &&
        this.ticket.text &&
        this.ticket.category
      ) {
        this.createTicket(this.ticket);
        this.sent = true;
        this.validationError = false;
      } else {
        this.validationError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.row {
  margin-bottom: 14px;
}
.error {
  border: 1px solid #da6d78;
}
.create-ticket-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
