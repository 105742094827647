<template>
  <div class="attendee-record" @mouseover="mouseOver()" @mouseout="mouseOut()">
    <div class="row">
      <div class="col-3">
        <h2 class="course-name">{{ attendee.course.name }}</h2>
        <small
          v-if="
            !attendee.course.type.includes('lfct') &&
              !attendee.course.type.includes('lfcs')
          "
          >Kubernetes {{ removeLetters(attendee.courseVersion) }}</small
        ><small v-if="attendee.courseVersion === '1.30b'" style="color: white"
          >b</small
        >
      </div>
      <div class="col-9">
        <p class="pull-right light-text">{{ attendee.uuidShort }}</p>
      </div>
    </div>
    <br />
    <div class="pull-left">
      <small>{{ infoText() }}</small>
    </div>
    <div class="pull-right light-text" v-if="!attendee.activated">
      {{ activateBeforeText() }}
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: 'AttendeeListItem',
  props: {
    attendee: {
      required: true,
    },
  },
  data: function() {
    return {
      hover: false,
    };
  },
  methods: {
    removeLetters: function(string) {
      string = string.replace('a', '');
      string = string.replace('b', '');
      return string.replace('c', '');
    },
    mouseOver: function() {
      this.hover = true;
    },
    mouseOut: function() {
      this.hover = false;
    },
    expiresInDays: function(dateString) {
      let expiresAt = Date.parse(dateString);
      const now = new Date();
      const diffTime = Math.abs(expiresAt - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    activateBeforeText: function() {
      let a = this.attendee;
      if (a.expired) {
        return 'expired ' + a.expiresAtDateString;
      } else {
        if (this.expiresInDays(a.expiresAtDateString) >= 360) {
          return 'expires in one year';
        } else {
          return 'activate before ' + a.expiresAtDateString;
        }
      }
    },
    infoText: function() {
      let a = this.attendee;
      if (a.notActivated) {
        if (a.expired) {
          return 'Expired';
        } else if (this.hover) {
          return 'Select to get to Activation Confirmation';
        }
      }
      if (a.activated) {
        if (a.terminal.error) {
          return 'Error';
        } else {
          if (a.sessionStarted && !a.terminal.expired) {
            if (a.sessionMinutesLeft > 0) {
              return (
                'Session is running, ' +
                a.sessionMinutesLeft +
                ' minutes left. Environment expires in ' +
                a.terminal.expiresAtInRelative
              );
            } else {
              return (
                'Session completed, environment expires in ' +
                a.terminal.expiresAtInRelative
              );
            }
          }
          if (a.terminal.expired) {
            return 'Session completed';
          } else {
            if (
              !a.sessionStarted &&
              (a.terminal.active || a.terminal.resume || a.terminal.suspend)
            ) {
              return (
                'Activated but session not started. You need to start the session before the environment expires in ' +
                a.terminal.expiresAtInRelative
              );
            }
            if (!a.sessionStarted && a.terminal.activating) {
              return 'Creating and initializing environment';
            }
            if (a.terminal.inactive) {
              return 'Error';
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.attendee-record {
  .course-name {
    margin: 0;
  }
  min-height: 100px;
  margin: 10px 0 0 0;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  padding: 10px;
  .light-text {
    color: #cbcece;
  }
}
</style>
