<template>
  <div class="sign-in-form">
    <h1>Sign into <b>Killer Shell</b></h1>
    <br />

    <span v-if="showLoginMethods()">
      <span v-if="isLoading">loading authentication methods...</span>
      <span v-if="error">
        An error occurred, please reload page again or contact support.
      </span>

      <p v-if="registrationEnabled === false" class="alert alert-info">
        New registrations are currently disabled.
      </p>

      <ul>
        <li
          v-for="method in methods"
          v-bind:key="method.provider"
          class="third-party"
        >
          <a
            v-if="method.provider === 'linuxfoundation'"
            v-bind:href="method.url"
          >
            <div class="login-method-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="mr-3 pull-left svg-inline--fa fa-google fa-w-16 fa-2x"
                aria-hidden="true"
                focusable="false"
                width="1em"
                height="1em"
                style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M4.8 19.2h9.6V24H0V9.6h4.8v9.6zM0 0v7.2h4.8V4.822h14.4V19.2h-2.4V24H24V0H0z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <span class="align-items-center provider-name">
              {{ getProviderName(method.provider) }}
            </span>
          </a>
          <a v-else v-bind:href="method.url">
            <div class="login-method-icon">
              <font-awesome-icon
                :icon="['fab', getIcon(method.provider)]"
                size="2x"
                class="mr-3 pull-left"
              />
            </div>
            <span class="align-items-center provider-name">
              {{ getProviderName(method.provider) }}
            </span>
          </a>
        </li>
        <li class="third-party" @click="toggleEmailLogin()">
          <a href="#" @click.prevent>
            <div class="login-method-icon">
              <font-awesome-icon
                icon="envelope-square"
                size="2x"
                class="mr-3 pull-left"
              />
            </div>
            <span class="align-items-center provider-name">
              Email
            </span>
          </a>
        </li>
      </ul>
    </span>

    <span v-if="showEmailLogin()">
      <AuthEmailSend></AuthEmailSend>
      <button
        class="btn btn-link link-normal"
        @click="toggleEmailLogin()"
        style="margin-top: 50px"
      >
        back
      </button>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '../../store/modules/authentication/status/mutation_types';
import router from '@/router/router';
import AuthEmailSend from '@/components/authentication/AuthEmailSend';

export default {
  components: {
    AuthEmailSend,
  },
  computed: mapGetters({
    error: 'authentication/oauth/error',
    methods: 'authentication/oauth/methods',
    registrationEnabled: 'authentication/oauth/registrationEnabled',
    isLoading: 'authentication/oauth/isLoading',
    jwtRefreshToken: 'authentication/status/jwtRefreshToken',
  }),
  props: {
    prevRoute: Object,
  },
  data: function() {
    return {
      refreshJwtTokenDone: false,
      emailLogin: false,
    };
  },
  created() {
    this.$store.dispatch('authentication/status/refreshJwtToken');
    this.getMethods();
  },
  mounted: function() {
    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case 'authentication/status/' + types.SET_IS_AUTHENTICATED:
          if (mutation.payload) {
            router.push(this.prevRoute);
          }
          this.refreshJwtTokenDone = true;
          break;
      }
    });
  },
  methods: {
    ...mapActions({
      getMethods: 'authentication/oauth/getMethods',
    }),
    showLoginMethods: function() {
      return (
        (this.refreshJwtTokenDone || !this.jwtRefreshToken) &&
        !this.showEmailLogin()
      );
    },
    toggleEmailLogin: function() {
      this.emailLogin = !this.emailLogin;
    },
    showEmailLogin: function() {
      return this.emailLogin;
    },
    getIcon: function(provider) {
      if (provider === 'azure') {
        return 'microsoft';
      }
      return provider;
    },
    getProviderName: function(provider) {
      if (provider === 'azure') {
        return 'Microsoft';
      }
      if (provider === 'linuxfoundation') {
        return 'Linux Foundation';
      }
      return provider.charAt(0).toUpperCase() + provider.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
h1 {
  font-size: 1.1rem;
  color: var(--secondary-color);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sign-in-form {
  text-align: center;
  width: 500px;
  margin: 8% auto;
  background-color: #fff;
  padding: 2rem 3rem 4rem;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.third-party {
  padding: 10px 0;
  width: 100%;
  border: 1px solid var(--secondary-color);
  margin: 20px auto;
  position: relative;
  .login-method-icon {
    position: absolute;
    left: 10px;
  }
  a {
    color: var(--secondary-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a:hover {
    color: var(--primary-color);
    svg {
      font-size: 2.3em;
    }
    .provider-name {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 14px;
  }
  .sign-in-form {
    width: 90%;
    padding: 20px;
  }
}

@media screen and (max-width: 1040px) and (min-width: 769px) {
  .sign-in-form {
    width: 500px;
  }
}
</style>
