<template>
  <div
    class="container-fluid"
    id="page-container"
    v-bind:class="appTemplateName"
  >
    <flash-message class="flash-message"></flash-message>

    <div id="content-wrap">
      <nav>
        <b-navbar toggleable="md" type="dark" variant="info">
          <b-navbar-brand href="#">
            <router-link :to="{ name: 'Home' }">
              <img src="./assets/kllr_shll_logo.svg" alt="logo" height="40px" />
            </router-link>
          </b-navbar-brand>
          <span class="kubernetes-exam-simulator"
            >Linux Foundation Exam Simulators</span
          >

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                href="https://twitter.com/_killer_shell"
                target="_blank"
                style="padding-top: 5px"
              >
                <font-awesome-icon
                  :icon="['fab', 'twitter']"
                  size="1x"
                  class="mr-3 pull-left"
                />
              </b-nav-item>

              <b-nav-item
                href="/slack"
                target="_blank"
                style="padding-top: 5px"
                v-if="isAuthenticated && isFullMember()"
              >
                <font-awesome-icon
                  :icon="['fab', 'slack']"
                  size="1x"
                  class="mr-3 pull-left"
                />
              </b-nav-item>

              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'Support' }">
                  <font-awesome-icon icon="envelope" size="1x" class="mr-3" />
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link :to="{ name: 'CksSimulatorAbout' }">
                  CKS
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link :to="{ name: 'CkaSimulatorAbout' }">
                  CKA
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link :to="{ name: 'CkadSimulatorAbout' }">
                  CKAD
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link :to="{ name: 'LfcsSimulatorAbout' }">
                  LFCS
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link :to="{ name: 'LfctSimulatorAbout' }">
                  LFCT
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="!isAuthenticated">
                <router-link :to="{ name: 'Faq' }">
                  Faq
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="!isAuthenticated">
                <router-link :to="{ name: 'Pricing' }">
                  Pricing
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'Dashboard' }">
                  Dashboard
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'OrderSelectCreate' }">
                  Store
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'UserProfile' }">
                  Account
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="!isAuthenticated">
                <router-link :to="{ name: 'Login' }">
                  Login
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'Logout' }">
                  Logout
                </router-link>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </nav>

      <div>
        <Maintenance v-if="statusMaintenanceShowMessage"></Maintenance>
        <router-view v-else></router-view>
      </div>
    </div>

    <footer id="footer">
      <b-row>
        <b-col md="4" class="" sm="12">
          <b-row>
            <b-col md="4" sm="12">
              <span>
                <small>
                  <a
                    href="https://www.linkedin.com/in/kimwuestkamp"
                    target="_blank"
                  >
                    kim wüstkamp
                  </a>
                </small>
              </span>
            </b-col>
            <b-col md="4" sm="12">
              <span>
                <small>
                  <a href="https://busayyyo.github.io/" target="_blank">
                    design
                  </a>
                </small>
              </span>
            </b-col>
            <b-col md="4" sm="12">
              <span>
                <small>
                  <router-link :to="{ name: 'Faq' }">
                    faq
                  </router-link>
                </small>
              </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4" class="text-center" sm="12">
          <div style="margin-top: 5px">
            <b-img src="@/assets/k8s_logo_white.svg" width="50px" fluid></b-img>
          </div>
        </b-col>

        <b-col class="imprint" md="4" sm="12">
          <b-row>
            <b-col md="4" sm="12">
              <span>
                <router-link :to="{ name: 'OrderSelectCreate' }">
                  <small>store</small>
                </router-link>
              </span>
            </b-col>
            <b-col md="4" sm="12">
              <span>
                <router-link :to="{ name: 'Support' }">
                  <small>support</small>
                </router-link>
              </span>
            </b-col>
            <b-col md="4" sm="12">
              <span>
                <router-link :to="{ name: 'Legal' }">
                  <small>legal / privacy</small>
                </router-link>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </footer>

    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="true"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
    >
      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        OKAY
      </div>
    </vue-cookie-accept-decline>
    <CodeCopy></CodeCopy>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Maintenance from '@/components/static/Maintenance';
import CodeCopy from '@/components/util/CodeCopy';

export default {
  computed: mapGetters({
    isAuthenticated: 'authentication/status/isAuthenticated',
    appTemplateName: 'app/template/name',
    user: 'user/list/data',
    userIsLoading: 'user/list/isLoading',
    statusMaintenanceShowMessage: 'status/maintenance/showMessage',
  }),
  components: {
    CodeCopy,
    Maintenance,
  },
  mounted: function() {
    this.$store.dispatch('authentication/status/readRefreshJwtTokenFromCookie');
    this.$store.dispatch('authentication/status/refreshJwtToken');
  },
  methods: {
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';

.template-no-header-no-footer {
  nav,
  footer {
    display: none !important;
  }
}

.template-attendee-run {
  nav,
  footer {
    display: none !important;
  }

  #content-wrap {
    padding-bottom: 0 !important;
    height: 99.5vh;

    & > div {
      height: inherit !important;

      & > div {
        height: inherit !important;

        & > div {
          height: inherit !important;

          & > div {
            height: inherit !important;

            & > div {
              height: inherit !important;

              & > div {
                height: inherit !important;

                & > div {
                  height: inherit !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

:root {
  --primary-color: #de6161;
  --secondary-color: #3f3d56;
  --gradient-color: #c68493;
  --body-color: #555;
  --first-accent: #eeecf4;
  --second-accent: #f6d3d3;
  --ckad-color-blue: #407af4;
}

html,
body {
  height: 100%;
  width: 100%;
  color: var(--body-color) !important;
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

nav {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color),
    var(--secondary-color)
  );
}

footer,
footer a,
nav a {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(237 227 231) !important;
}

footer,
footer a,
nav a:hover {
  color: white !important;
  text-decoration: none !important;
}

nav a:hover {
  color: #fff;
  text-decoration: none;
}

.kubernetes-exam-simulator {
  color: #ffffff;
  font-family: 'Source Code Pro', sans-serif !important;
  font-size: larger;
  margin-left: 2rem;
}

footer span {
  display: inline-block;
  padding: 15px;
}

.imprint {
  text-align: right;
}

.container-fluid {
  padding: 0 !important;
}

.flash-message {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 99999;
}

.flash__message.info {
  background-color: #3f3d56 !important;
  color: white !important;
  border-radius: 0;
}

.flash__message.error {
  border-radius: 0;
}

#content-wrap {
  padding-bottom: 3.5rem;
}

.dg-container,
.dg-backdrop {
  z-index: 99999 !important;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  background-color: #3f3d56;
}

#page-container {
  position: relative;
  min-height: 100vh;
  background-color: var(--first-accent);
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .kubernetes-exam-simulator {
    display: none;
  }
  .imprint {
    text-align: center !important;
  }
  #footer {
    height: 17rem;
    text-align: center;
  }
  #content-wrap {
    padding-bottom: 18rem;
  }
  footer span {
    padding: 5px;
  }
}
</style>
