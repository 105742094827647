import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getAttendee = ({ commit, state, rootState }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let options = {};
  let data = {
    attendee: dataPassed[0],
    markUsed: dataPassed[1],
  };
  if (data.markUsed) {
    Object.assign(options, { headers: new Headers() });
    options.headers.set('killer-shell-mark-attendee-clusters-used', 'true');
  }

  fetch('attendees/' + data.attendee, options)
    .then(response => response.json())
    .then(data => {
      commit(types.SET_DATA, data);
      commit(types.SET_ERROR, null);
      commit(types.SET_ERROR_COUNTER, 0);
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      if (rootState.status.maintenance.active === false) {
        commit(types.SET_ERROR_COUNTER, state.errorCounter + 1);
      }
    });
};

const resetAction = ({ commit }) => {
  commit(types.RESET);
};

export { getAttendee, resetAction };
